<script lang="ts">
  import AlertMessage from "./AlertMessage.svelte"
  import { AlertSeverity } from "./alert-severity"
  import { isCryptocurrencyTransfers } from "/@/account-management/store/cryptocurrency"
  import { getColorScheme } from "/@/util/color-scheme"

  export let severity: AlertSeverity
  export let dismissible = false

  let cssClass = ""
  export { cssClass as class }

  let show = true

  const handleDismiss = () => {
    show = false
  }
  $: colorScheme = getColorScheme($isCryptocurrencyTransfers)
</script>

{#if show}
  <div
    class="alert box-border rounded-lg border border-solid alert-{severity} {cssClass}"
    style={`
      --info-color: ${colorScheme.ALERT_INFO_COLOR};
      --info-background-color: ${colorScheme.ALERT_INFO_BACKGROUND};
      --reminder-color: ${colorScheme.ALERT_REMINDER_COLOR};
      --reminder-background-color: ${colorScheme.ALERT_REMINDER_BACKGROUND};
      --error-color: ${colorScheme.ALERT_REMINDER_COLOR};
      --error-background-color: ${colorScheme.ALERT_REMINDER_BACKGROUND};
      --confirm-color: ${colorScheme.ALERT_CONFIRM_COLOR};
      --confirm-background-color: ${colorScheme.ALERT_CONFIRM_BACKGROUND};
    `}
  >
    {#if dismissible}
      <button
        class="dismiss-alert tw-icon-Exit font-x-large-600 relative float-right mr-large mt-medium text-general-primary-text"
        on:click={handleDismiss}
      />
    {/if}
    <AlertMessage {severity}>
      <slot />
    </AlertMessage>
  </div>
{/if}

<style lang="postcss">
  .alert {
    &-confirm {
      background-color: var(--confirm-background-color);
      border-color: var(---confirm-color);
      color: var(---confirm-color);
    }

    &-error {
      background-color: var(--reminder-background-color);
      border-color: var(--reminder-color);
      color: var(--reminder-color);
    }

    &-info {
      background-color: var(--info-background-color);
      border-color: var(--info-color);
      color: var(--info-color);
    }

    &-remind,
    &-important,
    &-quote_alert {
      background-color: var(--reminder-background-color);
      border-color: var(--reminder-color);
      color: var(--reminder-color);
    }
  }
</style>
